import { h } from "preact";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Lang } from "./types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "0",
    "& .MuiStepper-root": {
      padding: "15px",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface HorizontalLinearStepperProps {
  activeStep: number;
  lang: Lang;
  skipped: string[]
}

export default function HorizontalLinearStepper({ activeStep, lang, skipped }:HorizontalLinearStepperProps) {
  const classes = useStyles();

  const steps = [
    lang.stepsEnum[100],
    lang.stepsEnum[200],
    lang.stepsEnum[350],
    lang.stepsEnum[900],
    lang.stepsEnum[400],
  ];
  const filteredSteps = steps.filter((step) => !skipped.includes(step));

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {filteredSteps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                {index === activeStep ? label : null}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
