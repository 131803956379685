import { BookingCancellationData, Lang } from '../types';
import { Typography } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonIcon from '@material-ui/icons/Person';
import constants from "../../constants";
import moment from "moment-timezone";
import { useMemo } from 'preact/hooks';

interface BookingInformationProps {
  data:BookingCancellationData,
  lang: Lang
}

const BookingInformation = function ({data:bookingData, lang}:BookingInformationProps) {
  const timezoneMoment = useMemo(() =>
    moment.utc(bookingData.time).tz(constants.TIMEZONE), [bookingData.time]
  );

  const date = timezoneMoment.format('YYYY-MM-DD');
  const timeBookingDuration = timezoneMoment.format('HH:mm');

  const getCancellationMessage = () => {
    if (bookingData.cancellation_type === 'disabled') {
      return lang.cancellation.disableMessage;
    } else if (bookingData.cancellation_type === 'pay_fee') {
      return lang.cancellation.payFeeMessage;
    } else {
      return lang.cancellation.otherMessage;
    }
  }

  return (
    <>
      <h2 className="step-title">{bookingData.restaurant_name}</h2>
      <div className="box-summary cancel-info cancellation-container">
        <div className="cancel-info">

          {bookingData.status !== "cancelled" ?
          (
            <>
              <Typography className="cancel-title" >
                {lang.cancellation.greeting} {bookingData.first_name}
              </Typography>
              <Typography>
                {getCancellationMessage()}
              </Typography>
              <Typography>
                {bookingData.cancellation_type==='pay_fee' && bookingData.amount>0? `No-show fee amount: ${bookingData.amount} kr`: null}
              </Typography>
            </>
            ):(
            <>
              <Typography className="cancel-title" >
                {lang.cancellation.cancelTitle}
              </Typography>
              <Typography>
                {lang.cancellation.contact} {bookingData.restaurant_phone}
              </Typography>
            </>
            )
          }
        </div>
        <Typography className="cancel-subtitle" >
          {lang.cancellation.cancelSubtitle}
        </Typography>
        <div className="details">
          <div>
            <Typography className="info">
              <CalendarTodayIcon fontSize="medium" color="primary" />
              {timeBookingDuration}
            </Typography>
          </div>
          <div className="info">
            <Typography className="info">
              <AccessTimeIcon fontSize="medium" color="primary" />
              {date}
            </Typography>
          </div>
          <div className="info">
            <Typography className="info">
              <PersonIcon fontSize="medium" color="primary" />
              {bookingData.guests}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingInformation;
