import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Lang } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiButton-root": {
      fontSize: "20px",
    },
  }
}));

interface ButtonConfirmProps {
  lang: Lang;
  handleBack: (event: any) => void;
  disabled:boolean;
}

export default function ButtonBack({
  lang,
  handleBack,
  disabled,
}:ButtonConfirmProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={handleBack}
        disabled={disabled}
      >
        {lang.prev}
      </Button>
    </div>
  );
}
