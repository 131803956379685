import style from "./style.css";
import { ThemeProvider } from "@material-ui/styles";
import { useEffect, useState } from "preact/hooks";
import StepFooter from "../../components/StepFooter";
import { makeStyles } from "@material-ui/core/styles";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import LogoExpired from "../../assets/icons/expired.png";
import { ReviewsData } from '../../components/types/index';
import APIReviews from '../../services/reviewsAPI';
import ReviewSection from "../../components/Reviews/ReviewSection";
import useTheme from "../../hooks/useTheme";
import { selectContainerTheme } from '../../helpers/selectContainerTheme';
import { route } from 'preact-router';

let queryParams: string;

try {
  queryParams = window.location.pathname.split("/")[2] ?? "";
} catch (e) {
  queryParams = "";
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "0",
    "& .MuiStepper-root": {
      padding: "15px",
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const steps = ["Recensionsbesök", "Tack för din feedback"];

interface ReviewProps {
  path: string;
}

export default function Reviews({}:ReviewProps) {
  const [paramTheme, setParamTheme] = useState("");
  const [bookingData, setBookingData] = useState<ReviewsData>({} as ReviewsData);
  const [isReviewSubmitted, setIsReviewSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [canReview, setCanReview] = useState(true);
  const theme = useTheme(paramTheme);

  const classes = useStyles();

  async function getBooking() {
    const data = await APIReviews.getWidgetData(queryParams);

    if (data) {
      setParamTheme(data.theme);
      setBookingData(data);
    }
  }

  useEffect(() => {
    getBooking();
  }, []);

  const handleBooking = () => {
    route(`/?hash=${bookingData.widget_hash}`);
  };

  return (
    <div className={selectContainerTheme(paramTheme)}>
      <div class={style.reviews}>
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>
                      {index === activeStep ? label : null}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>

          <div className="view-container">
            { canReview ?
              <ReviewSection
                queryParams={queryParams}
                setIsReviewSubmitted={setIsReviewSubmitted}
                setActiveStep={setActiveStep}
                setCanReview={setCanReview}
                bookingData={bookingData}
                isReviewSubmitted={isReviewSubmitted}
              />
            :
              <div className="expired-container">
                <div className="image-expired-container">
                <img src={LogoExpired} className="expired-image" />
                </div>
                <p className="expired-text">Tyvärr, länken har upphört att gälla</p>
                <p>Kanske en ny bokning?</p>
              </div>
            }
            <button
              className={`${isReviewSubmitted || !canReview ? 'review-button-submitted': 'button-unsubmitted'}`}
              onClick={handleBooking}
            >
              NY BOKNING
            </button>
            <StepFooter />
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
}
