/* eslint-disable no-console */
import { AvalibleTimes, CaspecoHomeSettings, DayState, WidgetHomeMaitresInformation } from '../components/types';
import { APIBase } from './baseAPI';

class APIHome extends APIBase {
  async getMaitresInformation (paramHash:string, paramRestaurant:string, paramPromo:string|null) {
    let requestUrl = "";
      if (paramRestaurant !== "") {
        requestUrl = `/widget/maitres?r=${paramRestaurant}${paramPromo ? `&p=${paramPromo}` : ""}`;
      } else {
        requestUrl = `/widget/maitres?hash=${paramHash}${paramPromo ? `&p=${paramPromo}` : ""}`;
      }

    const request = new Request(
      this.getPath(requestUrl),
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    try {
      return await this.fetchJSON<WidgetHomeMaitresInformation>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  }

  async getAvailableTimes (dateBookingISO:string,unitId:string, paramPromo:string, system:string) {
    const headers = this.getDefaultHeaders();
    headers.append('system', system)

    const request = new Request(
      this.getPath(`/widget/caspeco/AvailableTimes?date=${dateBookingISO}&unitId=${unitId}${paramPromo ? `&p=${paramPromo}` : ""}`),
      {
        method: 'GET',
        headers: headers,
      },
    );

    try {
      return await this.fetchJSON<AvalibleTimes[]>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  }

  async getCaspecoSettings (unitId:string, system:string) {
    const headers = this.getDefaultHeaders();
    headers.append('system', system)

    const request = new Request(
      `https://cloud.caspeco.se/api/v1/booking/externalBookingSettings/${unitId}`,
      {
        method: 'GET',
        headers: headers,
      },
    );

    try {
      return await this.fetchJSON<CaspecoHomeSettings>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  }

  async getDayStates (fromDate:string, toDate:string, unitId:string, paramPromo:string, system:string) {
    const headers = this.getDefaultHeaders();
    headers.append('system', system);

    const request = new Request(
      this.getPath(`/widget/caspeco/DayStates?fromDate=${fromDate}&toDate=${toDate}&unitId=${unitId}${paramPromo ? `&p=${paramPromo}` : ""}`),
      {
        method: 'GET',
        headers: headers,
      },
    );

    try {
      return await this.fetchJSON<DayState[]>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  }
}

export default new APIHome()
