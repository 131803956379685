import { StateUpdater, useEffect, useState } from "preact/hooks";
import moment from 'moment-timezone';
import constants from "../constants";
import { lazy, Suspense } from "preact/compat";
import { CaspecoHomeSettings, DayState, Lang } from "./types";
import { AvalibleTimes, PickedParam } from './types/index';
import StepFooter from "./StepFooter";

const Step1 = lazy(() => import("./Step1"));
const Step2 = lazy(() => import("./Step2"));
const Step3 = lazy(() => import("./Step3"));
const Step4 = lazy(() => import("./Step4"));
const StepCardWithStripe = lazy(() => import("./StepCardWithStripe"));

interface ContentProps {
  activeStep: number;
  setActiveStep: StateUpdater<number>;
  skipped: string[];
  setSkipped: StateUpdater<any[]>;
  lang: Lang;
  locale: string;
  paramUnitId: string;
  paramSystem: string;
  paramsNoShow: PickedParam[];
  paramPromo: string;
  paramTheme: string;
  hideMessageForm: boolean;
  availableTimes: AvalibleTimes[];
  setAvailableTimes: StateUpdater<AvalibleTimes[]>
  caspecoSettings: CaspecoHomeSettings|undefined;
  dayStates: DayState[];
  setDayStates: StateUpdater<DayState[]>
}

export default function Content({
  activeStep,
  setActiveStep,
  skipped,
  setSkipped,
  lang,
  locale,
  paramUnitId,
  paramSystem,
  paramsNoShow,
  paramPromo,
  paramTheme,
  hideMessageForm,
  availableTimes,
  setAvailableTimes,
  caspecoSettings,
  dayStates,
  setDayStates
}: ContentProps) {
  const startOfMonth = `${moment().clone().tz(constants.TIMEZONE).startOf("month").format("YYYY-MM-DD")}T00:00:00.000Z`;
  const endOfMonth = `${moment().clone().tz(constants.TIMEZONE).add(1, "month").endOf("month").format("YYYY-MM-DD")}T00:00:00.000Z`;

  const [buttonTime, setButtonTime] = useState("");
  const [activeButtonTime, setActiveButtonTime] = useState("");
  const [buttonTimeEnd, setButtonTimeEnd] = useState("");
  const [guests, setGuests] = useState("");
  const [dateBooking, setDateBooking] = useState(moment().tz(constants.TIMEZONE).format());
  const [selectedParamNoShow, setSelectedParamNoShow] = useState< PickedParam|null>(null);

  const unitId = String(paramUnitId);
  const [fromDate, setFromDate] = useState(startOfMonth);
  const [toDate, setToDate] = useState(endOfMonth);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [wantNewsRestaurant, setWantNewsRestaurant] = useState(false);
  const [wantNewsMaitres, setWantNewsMaitres] = useState(false);

  const [bookEmail, setBookEmail] = useState("");
  const [bookPhone, setBookPhone] = useState("");

  const [globalBookingNumber, setGlobalBookingNumber] = useState("");
  const [ruleId, setRuleId] = useState("");

  const [recoupTime, setRecoupTime] = useState(0);
  const [displayMessageBox, setDisplayMessageBox] = useState(false);

  useEffect(() => {
    const pickedParamNoShow = paramsNoShow?.find((param) =>
      param.time_set_ids.includes(+ruleId)
    );

    setSelectedParamNoShow(pickedParamNoShow!);

    const hasMinQuests = +guests >= pickedParamNoShow?.min_guests!;
    const hasTimeId = pickedParamNoShow?.time_set_ids;
    const hasAvailableDay = pickedParamNoShow?.days_of_week.includes(
      moment(dateBooking).day()
    );

    if (
      pickedParamNoShow !== undefined &&
      hasMinQuests &&
      hasTimeId &&
      hasAvailableDay
    ) {
      setSkipped(skipped.filter((skip) => skip !== lang.stepsEnum[900]));
    } else {
      setSkipped([...skipped, lang.stepsEnum[900]]);
    }
  }, [guests, availableTimes, paramsNoShow, ruleId, dateBooking]);

  return (
    <>
      <div className="container">
        <Suspense fallback={<div />}>
          {activeStep === 0 && (
            <Step1
              lang={lang}
              locale={locale}
              unitId={unitId}
              system={paramSystem}
              buttonTime={buttonTime}
              setButtonTime={setButtonTime}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              guests={guests}
              setGuests={setGuests}
              dateBooking={dateBooking}
              setDateBooking={setDateBooking}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              setButtonTimeEnd={setButtonTimeEnd}
              setBookEmail={setBookEmail}
              setBookPhone={setBookPhone}
              availableTimes={availableTimes}
              setAvailableTimes={setAvailableTimes}
              setRuleId={setRuleId}
              activeButtonTime={activeButtonTime}
              setActiveButtonTime={setActiveButtonTime}
              setRecoupTime={setRecoupTime}
              setDisplayMessageBox={setDisplayMessageBox}
              paramPromo={paramPromo}
              caspecoSettings={caspecoSettings}
              dayStates={dayStates}
              setDayStates={setDayStates}
            />
          )}
          {activeStep === 1 && (
            <Step2
              lang={lang}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              phone={phone}
              setPhone={setPhone}
              email={email}
              setEmail={setEmail}
              message={message}
              setMessage={setMessage}
              displayMessageBox={displayMessageBox}
              hideMessageForm={hideMessageForm}
            />
          )}
          {activeStep === 2 && (
            <Step3
              unitId={unitId}
              lang={lang}
              buttonTime={buttonTime}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              skipped={skipped}
              firstName={firstName}
              lastName={lastName}
              phone={phone}
              email={email}
              guests={guests}
              dateBooking={dateBooking}
              buttonTimeEnd={buttonTimeEnd}
              setGlobalBookingNumber={setGlobalBookingNumber}
              wantNewsRestaurant={wantNewsRestaurant}
              setWantNewsRestaurant={setWantNewsRestaurant}
              acceptTerms={acceptTerms}
              setAcceptTerms={setAcceptTerms}
              wantNewsMaitres={wantNewsMaitres}
              setWantNewsMaitres={setWantNewsMaitres}
              ruleId={ruleId}
              system={paramSystem}
              message={message}
              globalBookingNumber={globalBookingNumber}
              recoupTime={recoupTime}
              selectedParamNoShow={selectedParamNoShow}
              paramPromo={paramPromo}
            />
          )}
          {activeStep === 3 && (
            <StepCardWithStripe
              unitId={unitId}
              lang={lang}
              buttonTime={buttonTime}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              firstName={firstName}
              lastName={lastName}
              phone={phone}
              email={email}
              guests={guests}
              dateBooking={dateBooking}
              setGlobalBookingNumber={setGlobalBookingNumber}
              wantNewsRestaurant={wantNewsRestaurant}
              acceptTerms={acceptTerms}
              wantNewsMaitres={wantNewsMaitres}
              ruleId={ruleId}
              system={paramSystem}
              message={message}
              paramPromo={paramPromo}
              paramTheme={paramTheme}
              locale={locale}
            />
          )}
          {activeStep === 4 && (
            <Step4
              system={paramSystem}
              unitId={paramUnitId}
              lang={lang}
              buttonTime={buttonTime}
              skipped={skipped}
              firstName={firstName}
              lastName={lastName}
              phone={phone}
              email={email}
              guests={guests}
              dateBooking={dateBooking}
              buttonTimeEnd={buttonTimeEnd}
              bookEmail={bookEmail}
              bookPhone={bookPhone}
              globalBookingNumber={globalBookingNumber}
              recoupTime={recoupTime}
              selectedParamNoShow={selectedParamNoShow}
            />
          )}
        </Suspense>
      </div>
      <StepFooter />
    </>
  );
}
