import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .text-label": {
            fontSize: '1rem',
            lineHeight: '1.5'
        },
        "& .text-label .MuiLink-root": {
            display: 'inline-block',
        },
    },
}));

interface LabelWithLinkProps {
    label: string;
    url: string;
    isTargetBlank: boolean;
    expression?: string;
}

export default function LabelWithLink({
    label,
    url,
    isTargetBlank,
    expression
}: LabelWithLinkProps) {
    const classes = useStyles();
    expression = expression || '##';
    const expSize = expression.length;
    const init = label.indexOf(expression);
    if (init < 0) return withoutFormat();

    const end = label.indexOf(expression, init + expSize);
    if (end < 0) return withoutFormat();

    const initLbl = label.substr(0, init),
        linkText = label.substr((init + expSize), (end - init - expSize)),
        endLbl = label.substr((end + expSize), (label.length - 1));

    /**
     * Return default HTML result.
     * @returns Default results.
     */
    function withoutFormat() {
        return (
            <div className={classes.root}>
                <p className="text-label">{label}</p>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <p className="text-label">
                {initLbl}<Link
                    target={isTargetBlank ? '_blank' : '_self'}
                    href={url}
                    rel={isTargetBlank ? 'noopener' : null}
                >
                    {linkText}
                </Link>{endLbl}
            </p>
        </div>
    );
}
