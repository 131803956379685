import { StateUpdater, useEffect, useMemo, useState } from "preact/hooks";
import styled, { css } from "styled-components";
import { CardError, Lang } from './types/index';

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

interface SplitFormProps {
  setCardErrors: StateUpdater<CardError>
  lang: Lang;
  paramTheme: string,
  mode?: 'extended'|'divided'
}

const SplitForm = ({ setCardErrors, lang, paramTheme, mode='extended' }: SplitFormProps) => {
  const [cardNumberErrors, setcardNumberErrors] = useState(true);
  const [expDateErrors, setExpDateErrors] = useState(true);
  const [cvcErrors, setCvcErrors] = useState(true);

  useEffect(() => {
    setCardErrors({
      cardNumber:cardNumberErrors,
      expDate:expDateErrors,
      cvc:cvcErrors
    })
  }, [cardNumberErrors, expDateErrors, cvcErrors])


  const options = useMemo(
    () => ({
      style: {
        base: {
          color: paramTheme === "dark" ? "#ffff" : paramTheme === "white" ? "#000" : "#ffff",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return (
    <form>
      <Label className="label__stripe">
        {lang.paymentInputs.CardNumber}
        <CardNumberElementStyled
          options={options}
          onChange={(e:any) => {
            setcardNumberErrors(e.error?.message);
          }}
        />
      </Label>

      <div className={mode==='divided'? 'splitcard__divided': ''}>
        <Label className="label__stripe">
        {lang.paymentInputs.ExpirationDate}
          <CardExpiryElementStyled
            options={options}
            onChange={(e:any) => {
              setExpDateErrors(e.error?.message);
            }}
          />
        </Label>
        <Label className="label__stripe">
        {lang.paymentInputs.CVC}
          <CardCvcElementStyled
            options={options}
            onChange={(e:any) => {
              setCvcErrors(e.error?.message);
            }}
          />
        </Label>
      </div>
    </form>
  );
};

export default SplitForm;

const Label = styled.label`
  font-weight: 300;
  letter-spacing: 0.025em;
  flex: 1
`;

const inputStyles = css`
  &.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 600px;
    padding: 18.5px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    border: 1px solid #addccf;
    color: white;
  }

  &.InputElement {
    color: white;
  }

  &input::placeholder {
    color: white;
  }

  input:focus,
  &.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  &.StripeElement.IdealBankElement,
  &.StripeElement.FpxBankElement,
  &.StripeElement.PaymentRequestButton {
    padding: 0;
  }

  &.StripeElement.PaymentRequestButton {
    height: 40px;
  }
`;

const CardNumberElementStyled = styled(CardNumberElement)`
  ${inputStyles}
`;

const CardExpiryElementStyled = styled(CardExpiryElement)`
  ${inputStyles}
`;

const CardCvcElementStyled = styled(CardCvcElement)`
  ${inputStyles}
`;
