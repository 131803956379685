interface InputDeliveryProps {
  label: string,
  placeholder: string,
  onChange: (e: any) => void,
  name:string,
  value: string,
  error: boolean
  errorMessage: string,
  type?: string
}

const InputDeliveryField = ({
  label,
  placeholder,
  onChange,
  name,
  value,
  error,
  errorMessage,
  type='text'
}:InputDeliveryProps) => {
  return (
    <div className={`content_input`}>
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        className={`input-delivery ${error? "error": ""}`}
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        value={value}
      />
      {
        error &&
        <>
          <div style={{height: '10px'}}></div>
          <p className='input-delivery-error'>{errorMessage}</p>
        </>
      }
    </div>
  );
};

export default InputDeliveryField;
