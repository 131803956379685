import React from 'react';
import LogoExpired from "../../assets/icons/expired.png";

interface ExpiredProps {
  anotherBooking: boolean;
  text: string;
}

const Expired = function ({text, anotherBooking=true}:ExpiredProps) {
  return (
    <div className="expired-container">
      <div className="image-expired-container">
      <img src={LogoExpired} className="expired-image" />
      </div>
      <p className="expired-text">{text}</p>
      {
        anotherBooking && <p>Kanske en ny bokning?</p>
      }
    </div>
  );
};

export default Expired;
