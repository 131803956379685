import TextField from "@material-ui/core/TextField";
import { forwardRef } from "preact/compat";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";

const phoneInput = (props:TextFieldProps, ref:any) => {

  return (
    <TextField
      {...props}
      inputRef={ref}
    />
  );
};
export default forwardRef(phoneInput);
