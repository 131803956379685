import { useState, StateUpdater } from 'preact/hooks';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CardGiftcard  from '../../assets/icons/giftCard.png';
import { GiftCardAmountOption, Lang, Template } from '../types/index';
import ButtonNext from '../Button/ButtonNext';
import {  styled } from '@material-ui/styles';

const quantityOptions = [1,2,3,4,5,6,7,8,9,10];
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const amountRegex = /^[0-9]+$/;

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      fontSize: 16,
    }
  },
});


interface GiftCardOptionsStepProps {
  email:string;
  setEmail: StateUpdater<string>,
  quantity: number;
  setQuantity: StateUpdater<number>,
  amount: number;
  setAmount: StateUpdater<number>;
  options: GiftCardAmountOption[];
  setActiveStep: StateUpdater<number>;
  lang: Lang;
  paramRestaurant: string,
  error: boolean;
  cardType:string,
  setCardType:  StateUpdater<string>;
  presset: Template|undefined
}

export default function GiftCardOptionsStep({
  email,
  setEmail,
  quantity,
  setQuantity,
  amount,
  setAmount,
  options,
  setActiveStep,
  lang,
  paramRestaurant,
  error,
  cardType,
  setCardType,
  presset
}:GiftCardOptionsStepProps) {
  const [customAmount, setCustomAmount] = useState('');
  const [customAmountError, setCustomAmountError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleInputChange = (setState:StateUpdater<any>) => (event:any) => {
    let newValue = event.target.value;
    if (event.target.name === 'email') {
      setEmailError(false);
    }
    if (event.target.name === 'amount') {
      setCustomAmountError(false);
      if (amountRegex.test(newValue) && parseInt(newValue, 10)>0) {
        setAmount(parseInt(newValue, 10)*100);
      }
      newValue = event.target.value;
    }
    setState(newValue);
  };

  const handleEmailBlur = (event:any) => {
    setEmailError(!emailRegex.test(event.target.value));
  };

  const handleAmountBlur = (event:any) => {
    const inputValue = event.target.value.trim();

    if (amountRegex.test(inputValue) && parseInt(inputValue, 10)>2) {
      setAmount(parseInt(inputValue, 10)*100);
      setCustomAmountError(false);
    } else {
      setCustomAmountError(true);
    }
  };

  const handleAmountOptionSelection = (itemNumber:number) => {
    setAmount(itemNumber);
    setCustomAmount('');
    setCustomAmountError(false);
  };

  const handleCardTypeSlection = (type:string) => {
    setCardType(type)
  }

  return (
    <>
      <h2 className="giftcard__title">{lang.giftcards.titleStep1}</h2>
      <Typography style={{ fontSize: '16px', lineHeight: '20px', marginBottom: '38px', fontWeight: '400' }}>
        {presset?.value==="skb"? lang.giftcards.descriptionStep1Skb :lang.giftcards.descriptionStep1}
      </Typography>

      <div className='giftcard__amount'>
        {
          options.map((option)=>(
            <div key={option.value} className={`giftcard__amount__item ${amount===option.value? 'active': ''}`} onClick={()=>handleAmountOptionSelection(option.value)}>
              {option.label}
            </div>)
          )
        }

        <div className="form-row">
          <CustomTextField
            id="customAmountBelopp"
            type="text"
            name="amount"
            label={lang.giftcards.customAmount}
            variant="outlined"
            fullWidth
            mt={20}
            value={customAmount}
            onChange={handleInputChange(setCustomAmount)}
            InputLabelProps={{
              style: { fontSize: 16 }
            }}
            error={customAmountError}
            helperText={customAmountError ? lang.giftcards.amountHelperText : ''}
            onBlur={handleAmountBlur}
            onFocus={() => setAmount(0)}
          />
        </div>
      </div>

      <div className='giftcard__type__card__container'>
        <div  className={`giftcard__amount__item type__card ${cardType==='digital'? 'active': ''}`} onClick={()=>handleCardTypeSlection('digital')}>
          {lang.giftcards.digitalCard}
        </div>
        <div  className={`giftcard__amount__item type__card ${cardType==='physical'? 'active': ''}`} onClick={()=>handleCardTypeSlection('physical')}>
          {lang.giftcards.physicalCard}
        </div>
      </div>

      <div class="giftcard__quantity__container">
        <FormControl variant="outlined" className={'hi'} style={{ width: '150px' }}>
          <InputLabel id="quantity-select">{lang.giftcards.quantity}</InputLabel>
          <Select
            labelId="quantity-select"
            id="demo-simple-select-outlined"
            value={quantity}
            onChange={handleInputChange(setQuantity)}
            label="Quantity"
            startAdornment={<Icon><img src={CardGiftcard} className='giftcard__quantity__icon' /></Icon>}
            style={{ textAlign: 'center'}}
          >
            {quantityOptions.map((itemValue) => <MenuItem key={`quantity-${itemValue}`} value={itemValue}>{itemValue}</MenuItem>)}
          </Select>
        </FormControl>

        <CustomTextField
          id="customAmountPostAddress"
          type="email"
          name="email"
          label={lang.giftcards.postAddress}
          variant="outlined"
          fullWidth
          mt={20}
          value={email}
          onChange={handleInputChange(setEmail)}
          InputLabelProps={{
            style: { fontSize: 16 }
          }}
          error={emailError}
          helperText={emailError ? lang.giftcards.emailHelperText : ''}
          onBlur={handleEmailBlur}
        />
      </div>

      {
        paramRestaurant==="" || error?
          <p style={{color: "red", textAlign: 'center'}}>{lang.giftcards.errorParameters}</p>
        :
          <ButtonNext
            lang={lang || {} as Lang}
            handleNext={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
            disabled={!(amount>0 && email!==''  && !emailError && !customAmountError) || paramRestaurant===""}
          />
      }
    </>
  );
}
