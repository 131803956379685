import * as Sentry from "@sentry/browser";
import constants from "../constants";
import { Integrations } from "@sentry/tracing";
import axios from "axios";

class SentryService {
    init() {
        if (!constants.SENTRY_DSN) {
            console.warn('Sentry DSN not exist. The system cannot track JS errors.');
            return;
        }

        // const appVersion = '0.9.0';
        // Sentry.init({
        //     dsn: constants.SENTRY_DSN,
        //     release: `restaurant-widget-preact@${appVersion}`,
        //     integrations: [new Integrations.BrowserTracing()],
        //     tracesSampleRate: 1.0,
        // });
    }

    registerSentryHandlers() {
        // Axios
        // axios.interceptors.response.use(
        //     (response) => response,
        //     (error) => {
        //         Sentry.captureException(error);
        //         return Promise.reject(error);
        //     },
        // );
        // Other
    }
}

export default new SentryService();
