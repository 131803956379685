import { CircularProgress } from "@material-ui/core";

interface LoaderProps {
  size?: 'normal' | 'small'
}

export default function Loader({size='normal'}:LoaderProps) {
  return (
    <div className={size==='normal'? "box-loader": "box-loader box-loader-small"}>
      <CircularProgress color="primary" />
    </div>
  );
}
