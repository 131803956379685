/* eslint-disable no-console */
import { BookingCancellationData } from '../components/types/index';
import { APIBase } from './baseAPI';

class APICancelation extends APIBase {
  async getBookingCancelationData(slug:string){
    const request = new Request(
      this.getPath(`/widget/caspeco/booking/${slug}`),
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    try {
      return await this.fetchJSON<BookingCancellationData>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  }

  async cancelBookingRequest(code:string, type:string){
    let body;

    if(type ==='pay_fee'){
      body =JSON.stringify({
        pay:true
      })
    }

    const request = new Request(
      this.getPath(`/widget/caspeco/booking/${code}/cancel`),
      {
        method: 'POST',
        headers: this.getDefaultHeaders(),
        body: body
      },
    );

    try {
      await this.fetchResponse(request);
      return true
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
      return false;
    }
  }
}

export default new APICancelation()
