import style from "./style.css";
import HorizontalLinearStepper from "../../components/HorizontalLinearStepper";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import { useEffect, useState } from "preact/hooks";
import Close from "@material-ui/icons/Close";
import axios from "axios";
import constants from "../../constants";
import ContainerAddCard from "../../components/ContainerAddCard";
import closeApp from "../../helpers/closeApp";
import { Lang } from "src/components/types";
import { addPromoParam } from '../../services/baseAPI';

const acceptedLang = ["en", "sv"];
let queryParams:string;

try {
  queryParams = window.location.pathname.split("/")[2] ?? "";
} catch (e) {
  queryParams = "";
}

interface HomeProps {
  path: string;
}

export default function Home({}:HomeProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [lang, setLang] = useState<Lang|null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState<string[]>([]);
  const [locale, setLocale] = useState("sv");
  const [paramLang, setParamLang] = useState("");
  const [paramTheme, setParamTheme] = useState("");
  const [bookingData, setBookingData] = useState<any>({});

  const theme = createTheme({
    palette: {
      type:
        paramTheme === "dark"
          ? paramTheme
          : paramTheme === "white"
          ? "light"
          : "dark",
      primary: {
        main: "#ADDCCF",
      },
    },
  });

  useEffect(() => {
    async function getBooking() {
      axios
        .get(addPromoParam(`${constants.APP_API_HOST}/widget/caspeco/booking/${queryParams}`))
        .then((response) => {
          const { data } = response;
          setParamLang(data.lang);
          setParamTheme(data.theme);
          setLocale(acceptedLang.includes(paramLang) ? paramLang : "sv");
          setBookingData(data);
          if (data?.card_added) {
            setActiveStep(2);
          }
        })
        .catch((err) => {
          console.log(err.response);
          console.log(err);
        });
    }

    getBooking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  useEffect(() => {
    if (locale !== "") {
      axios
        .get<Lang>(addPromoParam(`/assets/lang/${locale}Messages.webBooking.json`))
        .then((response) => {
          setLang(response.data);
          setSkipped([
            response.data.stepsEnum[100],
            response.data.stepsEnum[200],
          ]);
          setIsLoading(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [locale]);

  return (
    <div
      className={
        paramTheme === "dark"
          ? paramTheme
          : paramTheme === "white"
          ? "light"
          : "dark"
      }
    >
      <div class={style.card}>
        {isLoading && (
          <ThemeProvider theme={theme}>
            <div className="header-container">
              <HorizontalLinearStepper
                lang={lang!}
                activeStep={activeStep}
                skipped={skipped}
              />
              <button aria-label="button-close" id="close-widget" onClick={closeApp}>
                <Close fontSize="large" color="primary" />
              </button>
            </div>
            <ContainerAddCard
              lang={lang!}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              skipped={skipped}
              bookingData={bookingData}
              queryParams={queryParams}
              paramTheme={paramTheme}
            />
          </ThemeProvider>
        )}
      </div>
    </div>
  );
}
