import { StateUpdater } from "preact/hooks";

interface RatingProps {
  rating: number;
  setRating: StateUpdater<number>;
  hover: number;
  setHover: StateUpdater<number>;
}


const Rating = ({rating, setRating, hover, setHover}:RatingProps) => {
  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
          index += 1;
        return (
          <button
              type="button"
              key={index}
              className={`rating    ${index <= (hover || rating) ? "filled-star" : "empty-star"}`}
              onClick={() => setRating(index)}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
          >
            <span >&#9733;</span>
          </button>
        );
      })}
    </div>
  );
};

export default Rating;
