import { makeStyles } from '@material-ui/core/styles';
import { useState, StateUpdater } from 'preact/hooks';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Loader from "../Loader";
import { BookingCancellationData, Lang } from "../types";
import apiCancellation from '../../services/cancellationAPI';
import { delay } from '../../helpers/delay';
import BookingInformation from './BookingInformation';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      marginTop: '20px',
    },
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .cancel-title': {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    '& .cancel-subtitle': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    '& .cancellation-container': {
      marginTop: '20px',
    },
  },
}));

interface CancelProps {
  bookingData: BookingCancellationData;
  id: string;
  getBooking: () => Promise<void>
  bookingIsLoaded:boolean;
  setBookingIsLoaded: StateUpdater<boolean>;
  lang: Lang;
}

export default function Cancel({
  bookingData,
  id,
  getBooking,
  bookingIsLoaded,
  setBookingIsLoaded,
  lang
}:CancelProps) {
  const classes = useStyles();
  const [canCancel, setCanCancel] = useState('');
  const steps = [1, 2];

  if ( bookingData.cancellation_type === "pay_fee"){
    setCanCancel(lang.cancellation.payAndCancel);
  } else {
    setCanCancel(lang.cancellation.cancelButton);
  }

  async function cancelBooking() {
    setBookingIsLoaded(false);
    const isSuccess = await apiCancellation.cancelBookingRequest(
      id,
      bookingData.cancellation_type
    );

    if (isSuccess) {
      await delay(2000); // needed to wait for backend update
      await getBooking();
    }

    setBookingIsLoaded(true);
  }

  const handleCancel = async () => {
    cancelBooking();
  };

  const handleCall = () => {
    window.open(`tel:${bookingData.restaurant_phone}`);
  };

  return (
    <div className={classes.root}>
      <div>
        {bookingData.status !== 'cancelled' ? (
          <div className='cancellation-container'>
            <Stepper activeStep={0}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>
                  {index === 1 ? '' : lang.cancellation.confirm}
                </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
        )
        :
        null
      }

      <BookingInformation lang={lang} data={bookingData} />

      <div className="cancel-button-container">
        { bookingData.status === "cancelled" ?
          <button onClick={handleCall} className="cancel-button outlined">
            {lang.cancellation.callRestaurant}
          </button>
        :
        (<div className="cancel-buttons">

        { bookingData.cancellation_type === "disabled" ?
          (
            <>
              <button onClick={handleCall} className="cancel-button outlined">
                {lang.cancellation.callRestaurant}
              </button>
            </>
          ):(
            <>
              <button onClick={handleCall} className="cancel-button outlined">
                {lang.cancellation.callRestaurant}
              </button>
              <button onClick={handleCancel} className="cancel-button">
                {canCancel}
              </button>
            </>
          )
        }
        </div>)}

      </div>
        { !bookingIsLoaded && <Loader />}
        </div>
    </div>
  );
}
