import { StateUpdater, useState } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import SplitCard from "./SplitCard";
import { Lang, CardError, Template } from './types/index';
import { StripeError } from "@stripe/stripe-js";
import giftCardAPI from "../services/giftCardAPI";
import ButtonConfirm from "./Button/ButtonConfirm";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "0.8rem",
    },
    "& .booking-date": {
      fontSize: "1.5rem",
    },
  },
}));

export interface StepCardProps {
  activeStep: number;
  setActiveStep: StateUpdater<number>;
  lang: Lang;
  paramTheme: string;
  amount: number;
  quantity: number;
  email: string;
  setGiftcardSlug: StateUpdater<string | null>;
  presset: Template|undefined;
  cardType: string;
  deliveryInfo:any
}

const StepSimplifiedCard = ({
  activeStep,
  setActiveStep,
  lang,
  paramTheme,
  amount,
  quantity,
  email,
  setGiftcardSlug,
  presset,
  cardType,
  deliveryInfo
}: StepCardProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const [cardErrors, setCardErrors] = useState<CardError>({
    cardNumber: true,
    expDate: true,
    cvc: true,
  });
  const [hasAddedCardError, setHasAddedError] = useState<StripeError|undefined>(undefined);
  const [sendingData, setSendingData] = useState(false);
  const [errorSendData, setErrorSendData] = useState(false);

  const sendData = (paymentMethod:any) => {
    const data = {
      email,
      payment_method:paymentMethod,
      preset:presset?.value,
      amount,
      count:quantity,
      type: cardType,
      first_name: deliveryInfo.firstName,
      last_name: deliveryInfo.lastName,
      address: deliveryInfo.address,
      city: deliveryInfo.city,
      postal_code: deliveryInfo.postCode
    };

    giftCardAPI.buyGiftCard(data).then((response) => {
      if (response) {
        setGiftcardSlug(response.slug);
        setSendingData(false);
        setActiveStep(prev=>prev+1);
      } else {
        setErrorSendData(true);
      }
    });
  };

  const confirmCard = async () => {
    try {
      let client_secret;

      const response = await giftCardAPI.getStripeClient();
      if (response) client_secret = response.client_secret;

      const { setupIntent, error } = await stripe!.confirmCardSetup(
        client_secret || '',
        {
          payment_method: {
            card: elements!.getElement(CardNumberElement)!,
          },
        }
      );

      if (error) {
        setHasAddedError(error);
        setSendingData(false);
        return null;
      }

      return setupIntent.payment_method;
    } catch (error) {
      console.log({ error });
      return null;
    }
  };

  const processBooking = async () => {
    setSendingData(true);
    const paymentMethod = await confirmCard();
    if (paymentMethod) {
      sendData(paymentMethod);
    } else {
      setTimeout(() => {
        setHasAddedError(undefined);
      }, 8000);
    }
  };

  return (
    <div className={classes.root}>
      <SplitCard
        setCardErrors={setCardErrors}
        lang={lang}
        paramTheme={paramTheme}
        mode="divided"
      />

      <ButtonConfirm
        activeStep={activeStep}
        lang={lang}
        acceptTerms={true}
        hasAddedCardError={hasAddedCardError}
        handleNext={processBooking}
        handleBack={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        sendingData={sendingData}
        errorSendData={errorSendData}
        hasCardError={
          cardErrors.cardNumber || cardErrors.expDate || cardErrors.cvc
        }
      />
    </div>
  );
};

export default StepSimplifiedCard;
