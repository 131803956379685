import axios from 'axios';
import constants from "../constants";

const api = axios.create({
  baseURL: `${constants.APP_API_HOST}`,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  }
});

export default api;


const getPromoParam = () => {
  let queryParams: URLSearchParams;

  try {
    queryParams = new URLSearchParams(window.location.search) ?? {};
  } catch (e) {
    queryParams = {} as URLSearchParams;
  }

  return queryParams.get("p") ?? null;
}

export const addPromoParam = (url: string): string => {
  const promoParam = getPromoParam()

  if (url.includes('p=') || !promoParam) {
    return url;
  }

  const hasParams = url.includes('?');

  const queryString = hasParams
    ? `${url.includes('=') ? '&' : ''}p=${promoParam}`
    : `?p=${promoParam}`;

  return url + queryString;
}

export class APIBase {
  getPath(resource: string): string {
    let finalPath = `${constants.APP_API_HOST}${resource}`
    return addPromoParam(finalPath);
  }

  getDefaultHeaders(): Headers {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return headers;
  }

  async fetchJSON<T>(request: RequestInfo): Promise<T> {
    const response = await fetch(request);

    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(JSON.stringify({ name: response.status, message: response.statusText, response: responseBody }));
    }
    if (request instanceof Request && request.headers) {
      if (request.headers.get('Content-Type') === 'application/json') {
        return response.json();
      }
    }
    return response.json();
  }

  async fetchResponse(request: RequestInfo): Promise<Response> {
    const response = await fetch(request);

    if (!response.ok) {
      const responseBody = await response.json();
      throw new Error(JSON.stringify({ name: response.status, message: response.statusText, response: responseBody }));
    }
    return response;
  }
}
