export default function closeApp(e:any) {
  e.preventDefault();
  if (window && window.parent) {
    window.parent.postMessage(
      {
        message: "close",
      },
      "*"
    );
  }
}
