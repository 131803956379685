export function convertToLinkedText(message:string|null) {
  if (!message) return null

  const emailRegex = /\b[\w-]+@[\w-]+\.[\w-]+\b/g;
  const phoneRegex = /(\d[0-9 \-]{6,}\d)/;
  const emailLink = '<a class="inner-link" href="mailto:$&">$&</a>';
  const phoneLink = '<a class="inner-link" href="tel:$&">$&</a>';

  const html = message
    .replace(emailRegex, emailLink)
    .replace(phoneRegex, phoneLink);

    return <span dangerouslySetInnerHTML={{ __html: html }} />;
}
