import StepSimplifiedCard from '../../components/StepCardSimplified';
import WithStripe from "../../components/WithStripe";
import CardGiftcard  from '../../assets/icons/giftCard.png';
import Typography from '@material-ui/core/Typography';
import { StateUpdater } from 'preact/hooks';
import { Lang } from '../types/Translations';
import { Template } from '../types';

interface GiftCardPaymentStepProps {
  amount: number;
  quantity: number;
  activeStep: number;
  setActiveStep: StateUpdater<number>;
  email:string;
  lang: Lang;
  paramTheme: string;
  setGiftcardSlug: StateUpdater<string | null>;
  presset: Template | undefined;
  deliveryInfo:any,
  cardType: string
}

const GiftCardPaymentStep = ({
  amount,
  quantity,
  activeStep,
  setActiveStep,
  email,
  lang,
  paramTheme,
  setGiftcardSlug,
  presset,
  deliveryInfo,
  cardType
}:GiftCardPaymentStepProps ) => {
  return (
    <>
      <h2 className="giftcard__title">{lang.giftcards.titleStep2}</h2>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: '32px'}}>
          <img src={CardGiftcard} width='24' height='16.47' className='giftcard__quantity__icon' style={{marginRight: '10px', paddingBottom:'0'}} />
          <Typography>
            {amount/100} SEK {lang.giftcards.amountToPay}
          </Typography>
          <Typography  style={{marginLeft: '6px'}}>
            x{quantity}
          </Typography>
        </div>
        <Typography>
          {quantity * amount / 100} SEK
        </Typography>
      </div>
      <WithStripe>
        <StepSimplifiedCard
          activeStep={activeStep}
          amount={amount}
          quantity={quantity}
          setActiveStep={setActiveStep}
          lang={lang}
          paramTheme={paramTheme}
          email={email}
          setGiftcardSlug={setGiftcardSlug}
          presset={presset}
          deliveryInfo={deliveryInfo}
          cardType={cardType}
        />
      </WithStripe>
    </>
  );
};

export default GiftCardPaymentStep;
