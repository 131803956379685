import {
  ListItemIcon,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { h } from "preact";
import PersonIcon from "@material-ui/icons/Person";
import MaterialUIPickers from "./MaterialUIPickers";
import { StateUpdater, useMemo } from "preact/hooks";
import { Day, Lang, AvalibleTimes } from './types/index';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "150px",
    },
    "&": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
        position: "absolute",
        top: "24px",
        left: "20px",
      },
      "& .MuiSelect-root": {
        paddingLeft: "45px",
      },
    },
  },
}));

interface NativeSelectProps {
  guests:string;
  setGuests: StateUpdater<string>;
  dateBooking: string;
  setDateBooking: StateUpdater<string>
  dayStates: Day[];
  availableTimes: AvalibleTimes[];
  lang: Lang;
  locale: string;
  setLoadedTimes:StateUpdater<boolean>;
  maxGuests: number;
  setFromDate: StateUpdater<string>;
  setToDate: StateUpdater<string>;
  setActiveButtonTime:StateUpdater<string>,
}

export default function NativeSelects({
  guests,
  setGuests,
  dateBooking,
  setDateBooking,
  dayStates,
  availableTimes,
  lang,
  locale,
  setLoadedTimes,
  maxGuests,
  setFromDate,
  setToDate,
  setActiveButtonTime
}:NativeSelectProps) {
  const classes = useStyles();
  const guestsMassive = useMemo(() => {
    const guests = [];

    for (let index = 0; index < maxGuests; index++) {
      guests.push({ value: index + 1, label: index + 1 });
    }

    guests.push({
      value: `maxGuests`,
      label: lang.start.moreThanMaxWebGuests + maxGuests,
    });
    return guests;
  }, [lang, maxGuests]);

  const handleChange = (event:any) => {
    setGuests(event.target!.value);
    setActiveButtonTime("");
  };

  return (
    <div className={classes.root}>
      <ListItemIcon>
        <PersonIcon fontSize="medium" color="primary" />
      </ListItemIcon>
      <TextField
        id="outlined-select-currency"
        select
        label={lang.guests}
        value={guests}
        onChange={handleChange}
        variant="outlined"
        className={classes.root}
        required
      >
        {guestsMassive.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <MaterialUIPickers
        dateBooking={dateBooking}
        setDateBooking={setDateBooking}
        dayStates={dayStates}
        availableTimes={availableTimes}
        lang={lang}
        locale={locale}
        setLoadedTimes={setLoadedTimes}
        setFromDate={setFromDate}
        setToDate={setToDate}
        setActiveButtonTime={setActiveButtonTime}
      />
    </div>
  );
}
