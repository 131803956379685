import Typography from '@material-ui/core/Typography';
import CardGiftcard  from '../../assets/icons/giftCard.png';
import { StateUpdater, useState } from 'preact/hooks';
import { Lang } from '../types';
import ButtonNext from '../Button/ButtonNext';
import ButtonBack from '../Button/ButtonBack';
import InputDeliveryField from './InputDelivery';


interface GiftCardDeliveryStepProps {
  amount: number;
  quantity: number;
  activeStep: number;
  setActiveStep: StateUpdater<number>;
  lang: Lang;
  deliveryInfo: any;
  setDeliveryInfo: StateUpdater<any>;
}

const GiftCardDeliveryStep = ({
  amount,
  quantity,
  deliveryInfo,
  setDeliveryInfo,
  lang,
  setActiveStep,
}: GiftCardDeliveryStepProps) => {

  const [localError, setLocalError] = useState({
    firstName: false,
    lastName: false,
    address: false,
    city: false,
    postCode: false,
  })

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setDeliveryInfo({ ...deliveryInfo, [name]: value });
    if (value ==="") {
      setLocalError({...localError, [name]: true})
    } else {
      setLocalError({...localError, [name]: false})
    }
  };

  const handleNext = () => {
    let errors = {
      firstName: false,
      lastName: false,
      address: false,
      city: false,
      postCode: false,
    }
    if ( Object.values(deliveryInfo).includes("")){
      for (const key in deliveryInfo) {
        if (deliveryInfo[key] === '') {
          errors = {...errors, [key]: true}
        }
      }
      setLocalError(errors)
      return
    }

    setActiveStep(prev => prev+1)
  }

  const handlePrev = () => {
    setActiveStep(prev => prev-1)
  }

  return (
    <>
      <h2 className="giftcard__title">{lang.giftcards.titleDelivery}</h2>
      <div className="giftcard_delivery_wrapper">
        <div className="giftcard_delivery_content">
          <img src={CardGiftcard} width='24' height='16.47' className='giftcard__quantity__icon' style={{marginRight: '10px', paddingBottom:'0'}} />
          <Typography>
            {amount/100} SEK {lang.giftcards.amountToPay}
          </Typography>
          <Typography  style={{marginLeft: '6px'}}>
            x{quantity}
          </Typography>
        </div>
        <Typography>
          {quantity * amount / 100} SEK
        </Typography>
      </div>
      <div className='giftcard_delivery_form'>

      <div className="content_address">
          <InputDeliveryField
            label={lang.giftcards.labelFirstName}
            placeholder={lang.giftcards.placeHolderFirstName}
            onChange={handleChange}
            name='firstName'
            value={deliveryInfo.firstName}
            error={localError.firstName}
            errorMessage={lang.giftcards.fieldRequiredMessage}
          />
          <InputDeliveryField
            label={lang.giftcards.labelLastName}
            placeholder={lang.giftcards.placeHolderLastName}
            onChange={handleChange}
            name='lastName'
            value={deliveryInfo.lastName}
            error={localError.lastName}
            errorMessage={lang.giftcards.fieldRequiredMessage}
          />
        </div>

        <InputDeliveryField
          label={lang.giftcards.labelAddress}
          placeholder={lang.giftcards.placeHolderAddress}
          onChange={handleChange}
          name='address'
          value={deliveryInfo.address}
          error={localError.address}
          errorMessage={lang.giftcards.fieldRequiredMessage}
        />

        <div className="content_address">
        <InputDeliveryField
            label={lang.giftcards.placeHolderPostCode}
            placeholder={lang.giftcards.placeHolderPostCode}
            onChange={handleChange}
            name='postCode'
            value={deliveryInfo.postCode}
            error={localError.postCode}
            errorMessage={lang.giftcards.fieldRequiredMessage}
            type='number'
          />
          <InputDeliveryField
            label={lang.giftcards.labelCity}
            placeholder={lang.giftcards.placeHolderCity}
            onChange={handleChange}
            name='city'
            value={deliveryInfo.city}
            error={localError.city}
            errorMessage={lang.giftcards.fieldRequiredMessage}
          />
        </div>
      </div>

      <div className="giftcard_delivery_buttons">
        <ButtonBack
          lang={lang}
          handleBack={handlePrev}
          disabled={false}
          />

        <ButtonNext
          lang={lang}
          handleNext={handleNext}
          disabled={false}
          />
      </div>
    </>
  );
};

export default GiftCardDeliveryStep;
