import { useEffect, useState } from "preact/hooks";
import { ThemeProvider } from "@material-ui/styles";
import Cancel from "../../components/Cancel/Cancel";
import { selectContainerTheme } from '../../helpers/selectContainerTheme';
import useTheme from '../../hooks/useTheme';
import apiCancellation from '../../services/cancellationAPI';
import { BookingCancellationData, Lang } from '../../components/types/index';
import Loader from '../../components/Loader';
import StepFooter from '../../components/StepFooter';
import Expired from '../../components/Expired/Expired';
import { isValidLanguage } from '../../helpers/isValidLanguage';
import { getMessages } from '../../helpers/getLanguageData';

const bookingID = window.location.pathname.split("/")[2] ?? "";

let queryParams:URLSearchParams;

try {
  queryParams = new URLSearchParams(window.location.search) ?? {};
} catch (e) {
  queryParams = {} as URLSearchParams;
}

const urlParamLang = isValidLanguage(queryParams.get("lang"));

interface CancellationProps {
  path: string;
}

export default function Cancellation({}:CancellationProps) {
  const [lang, setLang] = useState<Lang>(getMessages(urlParamLang || "sv"));
  const [paramTheme, setParamTheme] = useState("");
  const [bookingData, setBookingData] = useState<BookingCancellationData|null>(null);
  const [bookingIsLoaded, setBookingIsLoaded] = useState(false);
  const theme = useTheme(paramTheme);

  async function getBooking() {
    const data = await apiCancellation.getBookingCancelationData(bookingID);

    if (data) {
      setBookingData(data);
      setParamTheme(data.theme);
    }
    setBookingIsLoaded(true);
  }

  useEffect(() => {
    getBooking();
    const navigatorLang = (navigator.language || (navigator as any).userLanguage).split('-')[0];
    if(!urlParamLang){
      setLang(getMessages(isValidLanguage(navigatorLang) || "sv"))
    }
  }, []);

  const getContent = () =>{
    if (bookingData){
      return <Cancel
      bookingData={bookingData}
      id={bookingID}
      getBooking={getBooking}
      bookingIsLoaded={bookingIsLoaded}
      setBookingIsLoaded={setBookingIsLoaded}
      lang={lang}
    />;
    }
    return <div className="box-summary centered">
      <Expired
        text={lang.cancellation.expired}
        anotherBooking={false}
      />
    </div>;
  };

  return (
    <div className={selectContainerTheme(paramTheme)}>
      <div className='cancel'>
        <ThemeProvider theme={theme}>
          <div>
            {
              bookingIsLoaded || bookingData?
              getContent()
              :
              <Loader />
            }
            <StepFooter />
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
}
