import { useMemo } from 'react';
import { createTheme } from '@material-ui/core/styles';

function useTheme(paramTheme:string) {
  const theme = useMemo(() => createTheme({
    palette: {
      type:
        paramTheme === "dark"
          ? paramTheme
          : paramTheme === "white"
          ? "light"
          : "dark",
      primary: {
        main: "#ADDCCF",
      },
    },
  }), [paramTheme]);

  return theme;
}

export default useTheme;
