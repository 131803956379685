export default {
  APP_API_HOST: process.env.PREACT_APP_API_HOST,
  STRIPE_KEY: process.env.PREACT_APP_STRIPE_KEY,
  SENTRY_DSN: process.env.PREACT_APP_SENTRY_DSN,
  midYearProm: {
    FORMAT_DATE: 'YYYY-MM-DD hh:mm:ss',
    BASE_TZONE: 'Europe/Stockholm',
    START_DATE: '2022-07-01 00:00:01',
    END_DATE: '2022-09-01 00:00:00'
  },
  TIMEZONE: process.env.PREACT_APP_TIMEZONE || 'Europe/Stockholm',
};
