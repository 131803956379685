import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { StateUpdater, useMemo } from "preact/hooks";
import { Time } from "../types";

const useStyles = makeStyles((theme) => ({
  root: {
    "&": {
      margin: theme.spacing(1),
    },
  },
}));

interface ButtonTimeProps {
  setButtonTime: StateUpdater<string>;
  setButtonTimeEnd: StateUpdater<string>;
  time: Time;
  setRuleId: StateUpdater<string>;
  timeSetId: number;
  activeButtonTime: string;
  setActiveButtonTime: StateUpdater<string>
  recoupTime: number;
  setRecoupTime: StateUpdater<number>
}

export default function ButtonTime({
  setButtonTime,
  setButtonTimeEnd,
  time,
  setRuleId,
  timeSetId,
  activeButtonTime,
  setActiveButtonTime,
  recoupTime,
  setRecoupTime,
}:ButtonTimeProps) {
  const classes = useStyles();

  const DisabledTimeZoneStart = useMemo(() => {
    const tmp = time.start.replace(/[+Z].*$/, "");

    return tmp;
  }, [time.start]);

  const DisabledTimeZoneEnd = useMemo(() => {
    const tmp = time.end.replace(/[+Z].*$/, "");

    return tmp;
  }, [time.end]);

  return (
    <Button
      variant={
        activeButtonTime === `${timeSetId}-${DisabledTimeZoneStart}`
          ? "contained"
          : "outlined"
      }
      color={
        activeButtonTime === `${timeSetId}-${DisabledTimeZoneStart}`
          ? "primary"
          : "default"
      }
      onClick={() => {
        setActiveButtonTime(`${timeSetId}-${DisabledTimeZoneStart}`);
        setButtonTime(DisabledTimeZoneStart);
        setRuleId(timeSetId.toString());
        setButtonTimeEnd(DisabledTimeZoneEnd);
        setRecoupTime(recoupTime);
      }}
      className={`${classes.root} timeset-id-${timeSetId}`}
    >
      {moment(DisabledTimeZoneStart).format("HH:mm")}
    </Button>
  );
}
