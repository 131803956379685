import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import PersonIcon from '@material-ui/icons/Person';
import { StateUpdater, useEffect, useMemo, useState } from "preact/hooks";
import { Typography } from "@material-ui/core";
import { ReviewsData, RaitingPayload } from '../types/index';
import moment from "moment-timezone";
import constants from "../../constants";
import Rating from "./Rating";
import APIReviews from '../../services/reviewsAPI';
import "moment/dist/locale/sv";

const stringRatings = ['', 'Hemskt', 'Badkar', 'Normal', 'Bra','Utmärkt'];

interface ReviewSectionProps {
  isReviewSubmitted: boolean;
  bookingData: ReviewsData,
  queryParams: string;
  setIsReviewSubmitted: StateUpdater<boolean>;
  setActiveStep: StateUpdater<number>;
  setCanReview: StateUpdater<boolean>;
}

const ReviewSection = function ({
  queryParams,
  setIsReviewSubmitted,
  setActiveStep,
  setCanReview,
  bookingData,
  isReviewSubmitted,
}: ReviewSectionProps) {

  const [hover, setHover] = useState(0);
  const [rateValue, setRateValue] = useState('');
  const [commentValue, setCommentValue] = useState("");
  const [reviewRating, setReviewRating] = useState(0);

  useEffect(() => {
    setRateValue(stringRatings[hover]);
  }, [hover]);

  const noFormatDate = useMemo(() => (
    moment.utc(bookingData.time).tz(constants.TIMEZONE
  )), [bookingData.time]);

  const time = noFormatDate.format('HH:mm');
  const amount = (bookingData.bill_total)/100;
  const date = noFormatDate.format("DD MMM. YYYY");

  const sendData = async (payload:RaitingPayload) => {
    const data = await APIReviews.makeReview(queryParams, payload);

    if (data) {
      setIsReviewSubmitted(true);
      setCanReview(true);
      setActiveStep(1);
    } else {
      setIsReviewSubmitted(false);
      setCanReview(false);
    }
  };

  const handleFormSubmit = (event:any) => {
    event.preventDefault();

    if (reviewRating !== 0) {
      const paylaod:RaitingPayload = {
        rating: reviewRating,
        comment: commentValue,
      };
      sendData(paylaod);
    }
  };

  return (
    <div>
      <h2 className="step-title">{bookingData.restaurant_name}</h2>
      { isReviewSubmitted ?
      <div className="confirmation-review">
        <p className="confirmation-message"> Tack för din feedback!</p>
        <p>Din recension är viktig för oss</p>
      </div>
      :
      <div className="review-card">
        <div className="booking-info">
          <div className="booking-container">
            <span className="booking-details">
              <CalendarTodayIcon fontSize="medium" color="primary" />
              <p>{date}, kl {time}</p>
            </span>
            <span className="booking-details">
              <PersonIcon fontSize="medium" color="primary" />
              <p>{bookingData.guests} personer</p>
            </span>
          </div>
          {
            amount > 0 &&
          <div className="amount" >
            <p className="details"> Betald summa</p>
            <p className="details"> {amount}</p>
          </div>
          }
        </div>

        <div className="rate">
          <Typography className="rate-title"> Hur var ditt besök?</Typography>
          <Rating
            rating={reviewRating}
            setRating={setReviewRating}
            hover={hover}
            setHover={setHover}
          />
        </div>
        <span className="rating-value">{rateValue}</span>
        <div>
          <form onSubmit={handleFormSubmit}>
            <p className="text-area-title">Lämna en recension</p>

            <textarea
              placeholder="Vi älskar att höra din feedback! Kommentarer är valfria, men du får 15 poäng för varje recension som är minst 20 tecken lång."
              value={commentValue}
              onChange={(e:any) => setCommentValue(e.target.value)}
              className="text-area"
            />
            <button
              type="submit"
              className={`${reviewRating ? '':'review-button-disabled review-button'}review-button-enabled review-button`}
            >
              LÄMNA RECENSION
            </button>
          </form>
        </div>
      </div>
      }
    </div>
  );
};

export default ReviewSection;
