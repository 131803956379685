import { h } from "preact";
import { Router } from "preact-router";
import { lazy, Suspense } from "preact/compat";

const Home = lazy(() => import('../routes/home'));
const Card = lazy(() => import('../routes/card'));
const Reviews = lazy(() => import('../routes/reviews'));
const Cancellation = lazy(() => import('../routes/cancellation'));
const GiftCards = lazy(() => import('../routes/giftcards'));


function App() {
  return (
    <div id="app">
      <Suspense fallback={<div />}>
        <Router>
          <Cancellation path="/cancel/:id" />
          <Reviews path="/review/:id" />
          <Card path="/card/:params?" />
          <GiftCards path="/giftcards" />
          <Home path="/" />
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
