/* eslint-disable no-console */
import api, { APIBase }  from "./baseAPI";
import { ReviewsData, RaitingPayload, RaitingCreatedResponse } from '../components/types/index';

export const getWidgetData = async (slug:string) => {
  try {
    const { data } = await api.get<ReviewsData>(`/widget/booking/${slug}`);
    return data;
  } catch (error:any) {
    console.log(error.response);
    console.log(error);
  }
};

export const makeReview = async (slug:string, payload:RaitingPayload) => {
  try {
    const { data } = await api.post<RaitingCreatedResponse>(`/widget/review/${slug}`, payload);
    return data;
  } catch (error:any) {
    console.log(error.response);
    console.log(error);
  }
};

class APIReviews extends APIBase {
  getWidgetData = async (slug:string) => {
    const request = new Request(
      this.getPath(`/widget/booking/${slug}`),
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    try {
      return await this.fetchJSON<ReviewsData>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  };

  makeReview = async (slug:string, payload:RaitingPayload) => {
    const request = new Request(
      this.getPath(`/widget/review/${slug}`),
      {
        method: 'POST',
        headers: this.getDefaultHeaders(),
        body: JSON.stringify(payload),
      },
    );

    try {
      return await this.fetchJSON<RaitingCreatedResponse>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  };
}

export default new APIReviews()
