import moment from "moment";
import styled from "styled-components";
import { StateUpdater, useEffect, useState } from "preact/hooks";
import Loader from "../Loader";
import ButtonTime from "./ButtonTime";
import { AvalibleTimes, Section } from "../types";
import { convertToLinkedText } from "../../helpers/convertToLinkedText";

interface ButtonsSelectProps {
  setButtonTime: StateUpdater<string>;
  availableTimes: AvalibleTimes[];
  setButtonTimeEnd: StateUpdater<string>;
  guests: string;
  unitId: string;
  loadedTimes: boolean;
  setLoadedTimes: StateUpdater<boolean>;
  setRuleId: StateUpdater<string>
  activeButtonTime: string;
  setActiveButtonTime: StateUpdater<string>;
  setRecoupTime: StateUpdater<number>
}

export default function ButtonsSelect({
  setButtonTime,
  availableTimes,
  setButtonTimeEnd,
  guests,
  unitId,
  loadedTimes,
  setLoadedTimes,
  setRuleId,
  activeButtonTime,
  setActiveButtonTime,
  setRecoupTime,
}: ButtonsSelectProps) {
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    const mapSections:Section[] = [];

    if (availableTimes[0].id == unitId) {
      const sections = availableTimes[0].sections;
      sections.map((item) => {
        if (item.timeSets.length !== 0) {
          item.timeSets.map((timeSet) => {
            timeSet.times.map((time) => {
              time.id = timeSet.id;
            });
          });

          item.timeSets = item.timeSets.flatMap((current, i, array) => {
            const next = array[i + 1];
            if (next && current.title === next.title) {
              next.times = [...current.times, ...next.times];
              next.comment =
                current.comment !== undefined
                  ? current.comment
                  : "" || next.comment !== undefined
                  ? next.comment
                  : "";
              return [];
            }
            return current;
          });

          mapSections.push(item);
        }
      });
    }
    setLoadedTimes(true);
    setSections(mapSections);
  }, [availableTimes, setLoadedTimes, unitId]);

  return (
    <div>
      {loadedTimes === false && <Loader />}
      {loadedTimes === true &&
        guests !== "maxGuests" &&
        sections.map((section) => {
          return (
            <div key={`unit${section.id}`}>
              <h2 className="step-title section">{section.name}</h2>
              {section.timeSets.length !== 0 &&
                section.timeSets.map((timeSet) => {
                  if (timeSet.isTextRow === true) {
                    const recoupTime =
                      timeSet.recoupTime !== undefined ? timeSet.recoupTime : 0;
                    return (
                      <div key={timeSet.id}>
                        {timeSet.title !== "" && (
                          <h3 className="section-title">{timeSet.title}</h3>
                        )}
                        {timeSet.times.map((time) => {
                          if (
                            time.availableSeats.includes(guests) &&
                            moment(time.availableUntil.replace(/[+Z].*$/, "")).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            ) >=
                              moment()
                                .tz("Europe/Stockholm")
                                .format("YYYY-MM-DDTHH:mm:ss")
                          ) {
                            return (
                              <ButtonTime
                                setButtonTime={setButtonTime}
                                setButtonTimeEnd={setButtonTimeEnd}
                                time={time}
                                setRuleId={setRuleId}
                                timeSetId={time.id}
                                activeButtonTime={activeButtonTime}
                                setActiveButtonTime={setActiveButtonTime}
                                setRecoupTime={setRecoupTime}
                                recoupTime={recoupTime}
                                key={`unit${time.id}-${moment(
                                  time.start
                                ).format("HH:mm")}`}
                              />
                            );
                          }
                        })}
                        {timeSet.comment !== "" && (
                          <p className="step-text text-white">
                            {convertToLinkedText(timeSet.comment)}
                          </p>
                        )}
                      </div>
                    );
                    // eslint-disable-next-line no-else-return
                  } else {
                    for (let i = 0; i < timeSet.times.length; i++) {
                      const timeMap = timeSet.times[i];
                      if (
                        timeMap.availableSeats.includes(guests) &&
                        moment(timeMap.availableUntil).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        ) >=
                          moment()
                            .tz("Europe/Stockholm")
                            .format("YYYY-MM-DDTHH:mm:ss")
                      ) {
                        const recoupTime =
                          timeSet.recoupTime !== undefined
                            ? timeSet.recoupTime
                            : 0;

                        return (
                          <div key={timeSet.id}>
                            {timeSet.title !== "" && (
                              <h3 className="section-title">{timeSet.title}</h3>
                            )}
                            {timeSet.times.map((time) => {
                              if (
                                time.availableSeats.includes(guests) &&
                                moment(time.availableUntil.replace(/[+Z].*$/, "")).format(
                                  "YYYY-MM-DDTHH:mm:ss"
                                ) >=
                                  moment()
                                    .tz("Europe/Stockholm")
                                    .format("YYYY-MM-DDTHH:mm:ss")
                              ) {
                                return (
                                  <ButtonTime
                                    setButtonTime={setButtonTime}
                                    setButtonTimeEnd={setButtonTimeEnd}
                                    time={time}
                                    setRuleId={setRuleId}
                                    timeSetId={time.id}
                                    activeButtonTime={activeButtonTime}
                                    setActiveButtonTime={setActiveButtonTime}
                                    setRecoupTime={setRecoupTime}
                                    recoupTime={recoupTime}
                                    key={`unit${time.id}-${moment(
                                      time.start
                                    ).format("HH:mm")}`}
                                  />
                                );
                              }
                            })}
                            {timeSet.comment !== "" && (
                              <p className="step-text text-white">
                                {timeSet.comment}
                              </p>
                            )}
                          </div>
                        );
                      }
                    }
                  }
                })}
              <hr />
            </div>
          );
        })}
    </div>
  );
}

const StyledPhoneNum = styled.a`
  color: #fff;
  margin: 0 !important;
`;
