import style from "./style.css";
import HorizontalLinearStepper from "../../components/HorizontalLinearStepper";
import Content from "../../components/Content";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import { useEffect, useState } from "preact/hooks";
import Close from "@material-ui/icons/Close";
import closeApp from "../../helpers/closeApp";
import { AvalibleTimes, CaspecoHomeSettings, DayState, Params } from "src/components/types";
import { Lang } from '../../components/types/index';
import homeAPI from "../../services/homeAPI";
import { getMessages } from "../../helpers/getLanguageData";
import { isValidLanguage } from '../../helpers/isValidLanguage';

let queryParams:URLSearchParams;
let params:Params;

try {
  queryParams = new URLSearchParams(window.location.search) ?? {};
} catch (e) {
  queryParams = {} as URLSearchParams;
}

try {
  params = JSON.parse(window.name) ?? {};
} catch (e) {
  params = {} as Params;
}

const acceptedLang = ["en", "sv"];
const paramHash = queryParams.get("hash") ?? "";
const paramRestaurant = queryParams.get("r") ?? "";
const paramPromo = queryParams.get("p") ?? null;
const urlParamTheme = queryParams.get("theme") ?? "dark";
const urlParamLang =  isValidLanguage(queryParams.get("lang"));

interface HomeProps {
  path: string;
}

export default function Home({}:HomeProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [lang, setLang] = useState<Lang|null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState<string[]>([]);
  const [locale, setLocale] = useState("");
  const [paramUnitId, setParamUnitId] = useState("");
  const [paramSystem, setParamSystem] = useState("");
  const [paramLang, setParamLang] = useState("");
  const [paramTheme, setParamTheme] = useState(urlParamTheme);
  const [paramsNoShow, setParamsNoShow] = useState<any>("");
  const [hideMessageForm, setHideMessageForm] = useState<boolean|null>(null);
  const [availableTimes, setAvailableTimes] = useState<AvalibleTimes[]>([]);
  const [caspecoSettings, setCaspecoSettings] = useState<CaspecoHomeSettings>();
  const [dayStates, setDayStates] = useState<DayState[]>([]);


  useEffect(() => {
    if (paramHash !== "" || paramRestaurant !== "") {
      homeAPI.getMaitresInformation(
        paramHash,
        paramRestaurant,
        paramPromo,
        ).then(data => {

          if (data) {
            const {available_times, widget_params, widget_settings} = data;

            setAvailableTimes(available_times);
            setCaspecoSettings(widget_settings);
            setParamUnitId(widget_params.unitId);
            setParamSystem(widget_params.system);
            setParamLang(widget_params.lang);
            setParamTheme(widget_params.theme);
            setParamsNoShow(widget_params.noshow);
            setLocale(acceptedLang.includes(paramLang) ? paramLang : "sv");
            setHideMessageForm(widget_params.hide_message_form);
            setDayStates(dayStates);
          }
      });
    } else {
      setParamUnitId(queryParams.get("unitId") ?? params.unitId ?? 13);
      setParamSystem(queryParams.get("system") ?? params.system ?? "se_testbb");
      setParamLang(params.lang ?? acceptedLang[1]);
      setParamTheme(params.theme ?? "dark");
      setParamsNoShow(params.noshow ?? []);
      setLocale(acceptedLang.includes(paramLang) ? paramLang : "sv");
      setHideMessageForm(params.hide_message_form);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramHash, paramRestaurant]);

  const theme = createTheme({
    palette: {
      type:
        paramTheme === "dark"
          ? paramTheme
          : paramTheme === "white"
          ? "light"
          : "dark",
      primary: {
        main: "#ADDCCF",
      },
    },
  });

  useEffect(() => {
    if (locale !== "") {
      const data = getMessages(urlParamLang || locale);
      setLang(data);
      setSkipped([data.stepsEnum[900]]);
      setIsLoading(true);
    }
  }, [locale, paramLang, paramUnitId]);

  const themeElection = paramTheme === "dark"
    ? paramTheme
    : paramTheme === "white"
    ? "light"
    : "dark";

  const bodyElement = document.querySelector('body');
  if (bodyElement) {
    bodyElement.className = "";
    bodyElement.classList.add(themeElection);
  }

  return (
    <>
      {isLoading && (
          <div className={themeElection}>
            <div className={style.home}>
              {isLoading && (
                <ThemeProvider theme={theme}>
                  <div className="header-container">
                    <HorizontalLinearStepper
                      lang={lang!}
                      activeStep={activeStep}
                      skipped={skipped}
                    />
                    <button aria-label="button-close" id="close-widget" onClick={closeApp}>
                      <Close fontSize="large" color="primary" />
                    </button>
                  </div>
                  <Content
                    hideMessageForm={hideMessageForm!}
                    lang={lang!}
                    locale={urlParamLang || locale}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    skipped={skipped}
                    setSkipped={setSkipped}
                    paramUnitId={paramUnitId}
                    paramSystem={paramSystem}
                    paramsNoShow={paramsNoShow}
                    paramPromo={paramPromo!}
                    paramTheme={paramTheme}
                    availableTimes={availableTimes}
                    setAvailableTimes={setAvailableTimes}
                    caspecoSettings={caspecoSettings}
                    dayStates={dayStates}
                    setDayStates={setDayStates}
                  />
                </ThemeProvider>
              )}
            </div>
          </div>
      )}
    </>
  );
}
