import { Lang } from '../components/types/Translations';

const getCustomMessage = (system:string, unitId:string, lang:Lang) => {
  if (system === 'se_tststo' && unitId === '13') {
    return `${lang.customTennstopet.message}`
  } else if (system === 'se_testbb') {
    return '' ;
  }
    return null;
};

export default getCustomMessage;
