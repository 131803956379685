import Typography from '@material-ui/core/Typography';
import SuccessCardGiftcard  from '../../assets/icons/gift-card-success.png';
import Button from "@material-ui/core/Button";
import { useEffect, useState } from 'preact/hooks';
import giftCardAPI from "../../services/giftCardAPI";
import { BuyGiftCardStatus, Lang } from '../types';
import Loader from '../Loader';
import { Link } from "@material-ui/core";
import { convertToLinkedText } from '../../helpers/convertToLinkedText';

interface GiftCardSuccessStepProps {
  email: string,
  giftcardSlug: string|null,
  lang: Lang
}

const successStatus = ['paid', 'paid,physical']

const GiftCardSuccessStep = ({email, giftcardSlug, lang}:GiftCardSuccessStepProps) => {

  const [giftcardStatus, setGiftcardStatus] = useState<BuyGiftCardStatus>();

  useEffect(() => {
    if (giftcardSlug) {
      giftCardAPI.getGiftCardStatus(giftcardSlug).then((response) => {
        if (response) setGiftcardStatus(response);
      });
    }
  }, [giftcardSlug]);

  useEffect(() => {
    let intervalId:NodeJS.Timer;

    if (giftcardStatus?.status==='processing' && giftcardSlug) {
      intervalId = setInterval(() => {
        giftCardAPI.getGiftCardStatus(giftcardSlug).then((response) => {
          if (response) setGiftcardStatus(response);
        });
      }, 5000);
    }
    return () => clearInterval(intervalId);
  }, [giftcardStatus, giftcardSlug]);

  return (
  <>
    <div style={{textAlign: 'center', marginTop:'90px'}}>
      <img src={SuccessCardGiftcard} alt="" />
      <Typography style={{fontSize: '24px', fontWeight: 'bold', marginTop: '30px'}}>
        {successStatus.includes(giftcardStatus?.status as string)? lang.giftcards.success: lang.giftcards.processing}
      </Typography>
        {
          successStatus.includes(giftcardStatus?.status as string)?
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Typography style={{fontSize: '16px', marginTop: '50px', marginBottom: '40px', maxWidth: '500px'}}>
              {giftcardStatus?.status==='paid' && `${lang.giftcards.successDescription} ${email}`}
              {giftcardStatus?.status==='paid,physical' && convertToLinkedText(`${lang.giftcards.successDescriptionPhysical}`)}
            </Typography>
          </div>
          :
          <Loader size='small' />
        }
    </div>
    <div style={{display: 'flex', justifyContent: 'center', gap: '15px'}}>
      {
        (giftcardStatus?.download_url || giftcardStatus?.status==='paid,physical') &&
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={()=>window.location.reload()}
        >
          {lang.giftcards.buttonNewGiftcard}
        </Button>
      }
      {
        giftcardStatus?.download_url &&
        <Link href={giftcardStatus?.download_url} target="_blank" rel="noopener">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size={"large"}
            >
            {lang.giftcards.buttonDownload}
          </Button>
        </Link>
      }
    </div>
  </>
  );
};

export default GiftCardSuccessStep;
