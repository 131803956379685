import { Lang } from '../types/Translations';

interface CustomMessageProps {
  system: string;
  unitId: string;
  lang: Lang;
}

const CustomMessage = ({ system, unitId, lang }: CustomMessageProps) => {
  if (system === 'se_tststo' && unitId === '13') {
    return (
      <div className='box-summary'>
        <div className='box'>
          <p>{lang.customTennstopet.message}</p>
          <br />
          <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>** {lang.customTennstopet.confirmationNote}</p>
        </div>
      </div>
    );
  } else if (system === 'se_testbb') {
    return <></>;
  }
  return null
};

export default CustomMessage;
