import { APIBase } from './baseAPI';
import { BuyGiftCardStatus, StripeClientData, GiftCardSystemConfiguration } from '../components/types/index';

class APIGiftCards extends APIBase {
  async getSystemConfiguration (param:string, isHash: boolean = false) {
    const url = isHash?
      this.getPath(`/widget/giftcards?hash=${param}`)
      :
      this.getPath(`/widget/giftcards?r=${param}`);

    const request = new Request(
      url,
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    try {
      return await this.fetchJSON<GiftCardSystemConfiguration>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  }

  async getStripeClient () {
    const request = new Request(
      this.getPath(`/widget/stripe/setup`),
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    try {
      return await this.fetchJSON<StripeClientData>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  }

  async buyGiftCard(payload:any){
    const request = new Request(
      this.getPath(`/widget/giftcards`),
      {
        method: 'POST',
        headers: this.getDefaultHeaders(),
        body: JSON.stringify(payload)
      },
    );

    try {
      return await this.fetchJSON<BuyGiftCardStatus>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  }

  async getGiftCardStatus (slug:string) {
    const request = new Request(
      this.getPath(`/widget/giftcards/${slug}`),
      {
        method: 'GET',
        headers: this.getDefaultHeaders(),
      },
    );

    try {
      return await this.fetchJSON<BuyGiftCardStatus>(request);
    } catch (error:any) {
      console.log(error.response);
      console.log(error);
    }
  }
}

export default new APIGiftCards()
