import { StateUpdater, useState } from "preact/hooks";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import axios from "axios";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import constants from "../constants";
import ButtonConfirm from "./Button/ButtonConfirm";
import SplitCard from "./SplitCard";
import { Lang, DataForm, CardError } from './types/index';
import { StripeError } from "@stripe/stripe-js";
import { addPromoParam } from "../services/baseAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "0.8rem",
    },
    "& .booking-date": {
      fontSize: "1.5rem",
    },
  },
}));

export interface StepCardProps {
  buttonTime?: string;
  activeStep: number;
  setActiveStep: StateUpdater<number>;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  guests?: string;
  dateBooking?: string;
  setGlobalBookingNumber?: StateUpdater<string>;
  lang: Lang;
  acceptTerms?: boolean|undefined;
  wantNewsRestaurant?: boolean;
  wantNewsMaitres?: boolean;
  message?: string;
  ruleId?: string;
  system?: string;
  unitId?: string;
  typeFlow?: string;
  queryParams?: URLSearchParams | unknown
  paramPromo?: string;
  paramTheme: string;
}

const StepCard = ({
  buttonTime,
  activeStep,
  setActiveStep,
  firstName,
  lastName,
  phone,
  email,
  guests,
  dateBooking,
  setGlobalBookingNumber=()=>{},
  lang,
  acceptTerms,
  wantNewsRestaurant,
  wantNewsMaitres,
  message,
  ruleId,
  system,
  unitId,
  typeFlow,
  queryParams,
  paramPromo,
  paramTheme
}: StepCardProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const [cardErrors, setCardErrors] = useState<CardError>({
    cardNumber: true,
    expDate: true,
    cvc: true,
  });
  const [hasAddedCardError, setHasAddedError] = useState<StripeError|undefined>(undefined);
  const [sendingData, setSendingData] = useState(false);
  const [errorSendData, setErrorSendData] = useState(false);

  const sendData = (paymentMethod:any) => {
    let data;

    if (typeFlow === "AddCard") {
      data = {
        stripe_payment_method: paymentMethod,
      };
    } else {
      data = {
        contact: {
          email,
          fname: firstName,
          lname: lastName,
          mobile: phone,
        },
        guests,
        timeSetDate: moment(dateBooking)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .format("YYYY-MM-DDThh:mm:ss"),
        message,
        times: [{ ruleId: +ruleId!, start: buttonTime }],
        maitres: {
          stripe_payment_method: paymentMethod,
          caspeco_unit_id: unitId,
          news: wantNewsMaitres,
          restaurant_news: wantNewsRestaurant,
        },
      } as DataForm;

      data.maitres.p = paramPromo;
    }

    if (typeFlow === "AddCard") {
      return axios
        .patch(addPromoParam(`${constants.APP_API_HOST}/widget/caspeco/booking/${queryParams}`), data)
        .then(() => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        })
        .catch((err) => {
          setSendingData(false);
          setErrorSendData(true);
          console.log(err.response);
          console.log(err);
        });
    }

    return axios
      .post(addPromoParam(`${constants.APP_API_HOST}/widget/caspeco/WebBookings`), data, {
        headers: {
          system,
        },
      })
      .then((response) => {
        setGlobalBookingNumber(response.data.globalBookingNumber);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch((err) => {
        setSendingData(false);
        setErrorSendData(true);
        console.log(err.response);
        console.log(err);
      });
  };

  const confirmCard = async () => {
    try {
      const { client_secret } = await axios
        .get(addPromoParam(`${constants.APP_API_HOST}/widget/caspeco/stripe/setup`))
        .then((res) => res.data);

      const { setupIntent, error } = await stripe!.confirmCardSetup(
        client_secret,
        {
          payment_method: {
            card: elements!.getElement(CardNumberElement)!,
          },
        }
      );

      if (error) {
        setHasAddedError(error);
        setSendingData(false);
        return null;
      }

      return setupIntent.payment_method;
    } catch (error) {
      console.log({ error });
      return null;
    }
  };

  const processBooking = async () => {
    setSendingData(true);
    const paymentMethod = await confirmCard();
    if (paymentMethod) {
      await sendData(paymentMethod);
    } else {
      setTimeout(() => {
        setHasAddedError(undefined);
      }, 8000);
    }
  };

  return (
    <div className={classes.root}>
      <h1 className="step-title">{lang.stepsEnum[900]}</h1>

      <SplitCard
        setCardErrors={setCardErrors}
        lang={lang}
        paramTheme={paramTheme}
      />

      <ButtonConfirm
        activeStep={activeStep}
        lang={lang}
        acceptTerms={acceptTerms}
        hasAddedCardError={hasAddedCardError}
        handleNext={processBooking}
        handleBack={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
        sendingData={sendingData}
        errorSendData={errorSendData}
        hasCardError={
          cardErrors.cardNumber || cardErrors.expDate || cardErrors.cvc
        }
      />
    </div>
  );
};

export default StepCard;
